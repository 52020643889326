.filter_box {
    margin: 0% 3%;
    width: 100%;
    /* border: 1px solid #000; */
    background-color: #fff;
}
.active-div{
    border:2px solid red;
}

.div_active{
    margin: 3%;
    border-radius: 7px;
    padding: 3% 10%;
    float: left;
    cursor: pointer;
}

.div_notactive {
    margin: 3%;
    border-radius: 7px;
    padding: 3% 10%;
    float: left;
    cursor: pointer;
    background: #73ad21;
}

.d2_box{
    padding : 2%;
}
.filter_text{
    border: none;
    font-size: 17px;
    text-decoration: underline;
    text-align: center;
    display: block;
}
.h2_text{
margin:0;
}
.year_select {
    display: flex;  
    margin: 6% 3%;
        
}
.d2{
    width: 50%;
}
.div_filter_year {
    margin:0%
}
.year_text{
    margin: 3%;
    border-radius: 7px;
    background: #b9f16b;
    padding: 3% 10%;
    float: left;
    cursor: pointer;
}
.data_span{
    border-radius: 7px;
    background: #b9f16b;
    padding: 3% 10%;
    float: left;
    cursor: pointer;
}
.data_span_2{
    float: right;
    border-radius: 7px;
    background: #b9f16b;
    padding: 3% 10%;
    cursor: pointer;
    float: right;
}
.year_text_2{
    margin: 3%;
    float: right;
    border-radius: 7px;
    background: #b9f16b;
    padding: 3% 10%;
    cursor: pointer;
    float: right;
}

@media only screen and (max-width: 700px) {

.div_filter_year {
    margin: 0% 20%;
}
}