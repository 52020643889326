.home_layout {
    display: flex;  
    height: 90%;
}

.launch_div{
    height: inherit;
}
.rocket_metadata{
    margin: 5%;
}
.rocket_h{
    font-weight: bold;
    color: blue;
}
.lbl_rocket{
    font-weight: bold;
}
.img_section{

    padding: 10%;
}
.rocket_img{
    background-color:#c0c0c042;
    height: 120px;
    width: 150px;
}

.e1{
    width: 18%;
}
.e2{
    position: fixed;
    right: 0;
    z-index: 1;
    overflow-x: hidden;
    width: 82%;
}
.div_m1{
    margin: 0% 1%;  
    display: inline-block;
    overflow-y: scroll;
    height: 540px;
    min-width: 100%;
}
.text_rname{
    margin: 0% 5%;
}
.div_m1_l1{
    background-color: white;
    width: 20%;
    margin: 1% 2%;
    height: auto;
    min-height: 250px;
    display: inline-grid;
}




@media only screen and (max-width: 700px) {
    body {
      background-color: lightblue;
    }
    .div_m1 {
        margin: 0% 0% 0% 2%;
        display: block;
    }
    .home_layout {
        display: block;  
        height: 90%;
    }
    .rocket_metadata {
        margin: 3% 9%;
    }
    .text_rname {
        margin: 0 9%;
    }
    .rocket_img {
        background-color: hsla(0,0%,75.3%,.26);
        height: 160px;
        width: 302px;
    }
    .div_m1_l1 {
        background-color: #fff;
        width: 100%;
        height: auto;
        margin: 3% -1%;
        
    }
    .filter_box {
        margin :0%;
        width: 100%;
        background-color: #fff;
    }
    .e2 {
        position: relative;
        width: 100%;
    }
    .e1 {
        width: 100%;
    }
    .rocket_h {
        font-size: 20px;
        color: #00f;
    }
    .lbl_rocket, .rocket_h {
        font-weight: 700;
        font-size: 20px;
    }
  }

  @media screen and (min-device-width: 481px) and (max-device-width: 768px) { 

    .div_m1_l1 {
        background-color: #fff;
        width: 45%;
        height: auto;
        margin: 1% 1%;
    }

    .e2 {
        width: 65%;
    }
    .e1 {
        width: 35%;
    }
    .rocket_img {
        background-color: hsla(0,0%,75.3%,.26);
        height: 120px;
        width: 170px;
    }
    .rocket_metadata {
        margin: 2% 9%;
    }
    .text_rname {
        margin: 0 9%;
    }
    .div_m1 {
        margin: 0 3%;
        display: -webkit-inline-box;
        /* display: flex; */
    }
  }